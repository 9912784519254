import React, {Suspense} from 'react';
//import 'react-bootstrap';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import TopNav from "./html_includes/TopNav";
import Footer from "./html_includes/Footer";


import("./App.css").then(() => {
    //console.log('CSS loaded');
});
const About = React.lazy(() => import("./pages/About"));
const Contests = React.lazy(() => import("./pages/Contests"));
const Pricing = React.lazy(() => import("./pages/Pricing"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const ContestApp = React.lazy(() => import("./pages/seo/ContestApp"));
const CustomContests = React.lazy(() => import("./pages/seo/CustomContests"));
const Demo = React.lazy(() => import("./pages/Demo"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const Terms = React.lazy(() => import("./pages/Terms"));
const Shopify = React.lazy(() => import("./pages/seo/Shopify"));
const MailChimp = React.lazy(() => import("./pages/seo/MailChimp"));
const Integrations = React.lazy(() => import("./pages/seo/Integrations"));
const Wordpress = React.lazy(() => import("./pages/seo/WordPress"));
function App() {

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <TopNav/>
                </header>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/"
                               element={<Home title="Rewards Fuel contest software - boost subscribers & followers"/>}/>
                        <Route path="/reviews"
                               element={<Reviews title="Customer reviews for Rewards Fuel contest software"/>}/>
                        <Route path="/pricing" element={<Pricing title="Rewards Fuel pricing for contest software"/>}/>
                        <Route path="/contests" element={<Contests title="Rewards Fuel contest software features"/>}/>
                        <Route path="/features" element={<Contests title="Rewards Fuel contest software features"/>}/>
                        <Route path="/contact" element={<Contact title="Contact Rewards Fuel contest software"/>}/>
                        <Route path="/about" element={<About title="About Rewards Fuel contest software"/>}/>
                        <Route path="/demo" element={<Demo title="About Rewards Fuel contest software"/>}/>
                        <Route path="/privacy" element={<Privacy title="Rewards Fuel Giveaways and Contest Privacy Policy"/>}/>
                        <Route path="/terms" element={<Terms title="Rewards Fuel Giveaways terms and conditions"/>}/>
                        //seo pages
                        <Route path="/contest-app" element={<ContestApp title="Contest App Rewards Fuel"/>}/>
                        <Route path="/custom" element={<CustomContests title="Custom contests with help from Rewards Fuel"/>}/>
                        <Route path="/integrations" element={<Integrations title="Contest app - Rewards Fuel integrate with tons of services"/>}/>
                        <Route path="/integrations/shopify" element={<Shopify title="Shopify contest app - run contests right from your Shopify store."/>}/>
                        <Route path="/integrations/mailchimp" element={<MailChimp title="Mailchimp contest app - run contests that help grow your mailing list."/>}/>
                        <Route path="/integrations/wordpress" element={<Wordpress title="The Rewards Fuel Contest Plugin for WordPress, get started today."/>}/>
                    </Routes>
                </Suspense>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
