import React  from 'react';

import {Nav, Navbar, Container} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import 'alertifyjs/build/css/alertify.css';
const alertify = require ('alertifyjs');
function TopNav() {
    const handleSignup = (e) => {
        e.preventDefault();
        alertify.alert('Sign up', 'Sign up for Rewards Fuel contest software');
    }
    return (
        <Navbar  expand="md">
            <Container>
                <LinkContainer to="/">
                <Navbar.Brand>
                    <img src="/logo.svg" alt="contest software platform Rewards Fuel" width="35" height="35"
                                                className="top_logo"/>
                    Rewards Fuel</Navbar.Brand>
                </LinkContainer>

                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav className="justify-content-end" style={{ width: "100%" }}>
                        <LinkContainer to="/">
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/reviews">
                            <Nav.Link>Reviews</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/pricing">
                            <Nav.Link>Pricing</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/contests">
                            <Nav.Link>Contests</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/demo">
                            <Nav.Link>Demo</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/about">
                            <Nav.Link>About</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/contact">
                            <Nav.Link >Contact</Nav.Link>
                        </LinkContainer>
                        <Nav.Link className={"bolder"} onClick={handleSignup} href="#">Sign up</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default TopNav;