import React, {useEffect, Suspense} from 'react';
const HomePageHero = React.lazy(() => import( "../html_includes/HomePageHero"));
const FaqAccordion = React.lazy(() => import('../html_includes/FaqAccordion'));
const StyleStatsFeatures = React.lazy(() => import('../html_includes/StyleStatsFeatures'));
const WorksWellWithOthers = React.lazy(() => import( "../html_includes/WorksWellWithOthers"));
const PowerContests = React.lazy(() => import( "../html_includes/PowerContests"));
const SignIn = React.lazy(() => import( "../html_includes/SignIn"));
export default function (props) {
    useEffect(() => {
        document.title = props.title
    }, [])
    return (
        <div>
            <Suspense fallback={<div>Loading...</div>}>
                <HomePageHero/>
            </Suspense>
            <div className="home-page container-fluid">
                <Suspense fallback={<div>Loading...</div>}>
                    <StyleStatsFeatures/>
                    <PowerContests/>
                    <WorksWellWithOthers/>
                    <SignIn/>
                    <FaqAccordion/>
                </Suspense>
            </div>
        </div>
    );
}
