import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faTwitter, faWordpress, faYoutube
} from '@fortawesome/free-brands-svg-icons'

function Footer() {
    return (<footer className="footer-area">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/features/">Features</a></li>
                        <li><a href="/pricing/">Pricing</a></li>
                        <li><a href="/contest-app/" title="contest app">Contest app</a></li>
                    </ul>
                </div>
                <div className="col-md-4">
                    <ul>
                        <li><a href="/about/">About</a></li>
                        <li><a href="https://rewardsfuel.info" target="_blank">Blog</a></li>
                        <li><a href="/contact">Contact</a></li>
                        <li><a href="https://app.rewardsfuel.com">Login</a></li>
                    </ul>
                </div>
                <div className="col-md-4">
                    <ul>
                        <li><a href="/privacy/">Privacy</a></li>
                        <li><a href="/terms">Terms</a></li>
                        <li>
                            <div className="copyright">©2022 Rewards Fuel LLC</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="mt15 social-buttons">
                <a href="https://facebook.com/rewardsfuel" target="_blank"><FontAwesomeIcon icon={faFacebook}/></a>
                <a href="https://instagram.com/rewardsfuel" target="_blank"><FontAwesomeIcon icon={faInstagram}/></a>
                <a href="https://twitter.com/rewardsfuel" target="_blank"><FontAwesomeIcon icon={faTwitter}/></a>
                <a href="https://youtube.com/rewardsfuel" target="_blank"><FontAwesomeIcon icon={faYoutube}/></a>
                <a href="https://rewardsfuel.info" target="_blank"><FontAwesomeIcon icon={faWordpress}/></a>
            </div>
        </div>
    </footer>)
}

export default Footer;